import React from "react";
import {
  watchUserChanges,
  bingoSing,
  updateUser,
  watchTransmision,
} from "../services/firebase";

export const AuthContext = React.createContext({});

export const AuthContextConsumer = AuthContext.Consumer;

export class AuthContextProvider extends React.Component {
  state = {
    authReady: false,
    isLoggedIn: false,
    user: null,
    config: [],
    transmision: 0,
  };

  componentDidMount() {
    this.userWatcherUnsub = watchUserChanges((user) => {
      if (user) {
        this.setState({
          authReady: true,
          isLoggedIn: true,
          user,
        });
      } else {
        this.setState({
          authReady: true,
          isLoggedIn: false,
          user: null,
        });
      }
    });
    this.transmisionWatcherUnsub = watchTransmision((transmision) => {
      if (transmision) {
        this.setState({
          transmision,
        });
      }
    });
  }

  componentWillUnmount() {
    if (this.userWatcherUnsub) {
      this.userWatcherUnsub();
    }
    if (this.transmisionWatcherUnsub) {
      this.transmisionWatcherUnsub();
    }
  }

  bingoSing = async (callback, bingoSingData, userData) => {
    try {
      await bingoSing(
        callback,
        {
          ...bingoSingData,
          date: Date.now(),
          serverTimestamp: null
        },
        {
          ...userData,
          date: Date.now(),
          serverTimestamp: null
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  updateUser = async (callback, data) => {
    try {
      await updateUser(callback, data);
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <AuthContext.Provider
        value={{
          ...this.state,
          bingoSing: this.bingoSing,
          updateUser: this.updateUser,
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}
