import Background from "../../assets/image/Puntos.svg";
// import Fondo from "../../assets/image/FONDO.png";
// import Bk from "../../assets/image/bk.png";
import Volkswagen from "../../assets/fonts/SoftMakerVolkswagenSerial.otf";
const volkswagen = {
  fontFamily: "Volkswagen",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `url(${Volkswagen}) format('opentype')`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};
export default {
  "@global": {
    body: {
      backgroundImage: `url(${Background})`,
      // backgroundImage: `url(${Bk})`,Background
      backgroundSize: "10%",
      BackgroundAttachment: "fixed",
      minHeight: "100vh !important",
    },
    "@font-face": [volkswagen],
  },
};
