import React from 'react';
import { AuthContext } from '../context/auth';
import Loading from "../components/Loading";

class Root extends React.Component {
    render() {
        const {
            children,
        } = this.props;
        const {
            authReady,
        } = this.context;

        if (!authReady) {
            return <Loading />;
        }

        return children;
    };
}

Root.contextType = AuthContext;

export default Root;
