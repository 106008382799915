import React from "react";
// import { DialogContentText } from "@material-ui/core/";
import Data from "../../assets/image/TYC EN INGLES - PLAN DE PREMIOS.pdf";

export default class HabeusData extends React.Component {
  render() {
    return (
      <div
        id='alert-dialog-slide-description'
        color='textPrimary'
        align='justify'>
        <h2>TERMS AND CONDITIONS</h2>

        <a
          href={Data}
          target='_blank'
          rel='noopener noreferrer'>
          Terms, conditions, and personal data protection policy.
        </a>
      </div>
    );
  }
}
