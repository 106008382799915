import React from "react";
import {
  createUser,
  watchSelecteds,
  verifyId,
  invitedId,
  login,
} from "../services/firebase";

export const UserContext = React.createContext({});

export const UserContextConsumer = UserContext.Consumer;

export class UserContextProvider extends React.Component {
  state = {
    selecteds: null,
  };

  componentDidMount() {
    this.selectedsWatcherUnsub = watchSelecteds((selecteds) => {
      this.setState({ selecteds });
    });
  }

  componentWillUnmount() {
    if (this.selectedsWatcherUnsub) {
      this.selectedsWatcherUnsub();
    }
  }

  createUser = async (data, callback) => {
    let winners = [];
    for (var bi = 0; bi < data.numberOfCartons; bi++) {
      winners.push(1);
    }
    try {
      await createUser(
        {
          ...data,
          date: Date.now(),
          winners: winners,
          attempts: 0,
          toBlock: false
        },
        callback
      );
    } catch (error) {
      console.log(error);
    }
  };

  verifyId(values, callback) {
    verifyId(values, callback);
  }

  invitedId(values, callback) {
    invitedId(values, (result) => {
      callback(result);
    });
  }

  login = async (data, callback) => {
    try {
      return await login(data, callback);
    } catch (error) {
      console.log(error);
    }
  };

  //--- Funciones

  render() {
    const { children } = this.props;

    return (
      <UserContext.Provider
        value={{
          ...this.state,
          createUser: this.createUser,
          verifyId: this.verifyId,
          invitedId: this.invitedId,
          login: this.login,
        }}
      >
        {children}
      </UserContext.Provider>
    );
  }
}
