import React from "react";
import ReactDOM from "react-dom";

class Loading extends React.Component {
  render() {
    const node = <div>Cargando...</div>;

    return ReactDOM.createPortal(node, document.getElementById("loading"));
  }
}

export default Loading;
