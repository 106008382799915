const styles = (theme) => ({
  container: {
    paddingTop: "20px",
    paddingBottom: "20px",
    paddingLeft: "60px",
    paddingRight: "60px",
  },
  muiCardContent: {
    padding: "5px",
  },
  card: {
    display: "flex",
    maxHeight: "130px",
  },
  cardDetails: {
    flex: 1,
    overflow: "auto",
  },
  cardMedia: {
    width: 160,
  },
  sidebarAboutBox: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[200],
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  playerWrapper: {
    position: "relative",
    paddingTop: "56.25%",
    marginLeft: "-30px",
    marginRight: "-30px",
    paddingBottom: "34px",
  },
  reactPlayer: {
    position: "absolute",
    top: 0,
    left: 0,
    border: 0,
  },
  flotante: {
    textAlign: "center",
  },
  icon: {
    fontSize: "25px",
  },
  textSupport: {
    fontSize: "15px",
  },
  pack: {
    borderRadius: "10px",
    color: "#075e54",
    backgroundColor: "#fff",
    padding: "5px",
    cursor: "pointer",
  },
  flotante2: {
    position: "fixed",
    bottom: -10,
    left: 10,
  },
  [theme.breakpoints.down("lg")]: {
    icon: {
      fontSize: "15px",
    },
    textSupport: {
      fontSize: "12px",
    },
  },
  [theme.breakpoints.down("md")]: {
    container: {
      paddingTop: "20px",
      paddingBottom: "20px",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    icon: {
      fontSize: "12px",
    },
    textSupport: {
      fontSize: "10px",
    },
  },
  [theme.breakpoints.down("sm")]: {
    icon: {
      fontSize: "12px",
    },
    textSupport: {
      fontSize: "10px",
    },
  },
  marcas: {
    backgroundColor: "#ffffff",
    borderRadius: "15px",
    margin: "20px auto",
    width: "80%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  marcasImg: {
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
  },
  coljuegos: {
    width: "60%",
    margin: "50px",
    textAlign: "left",
    display: "block",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
});

export default styles;
