import React from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../context/auth";

class GuardRoute extends React.Component {
  render() {
    const { type, component: Component, ...rest } = this.props;

    const { isLoggedIn } = this.context;

    if (type === "private" && !isLoggedIn) {
      return <Redirect to="/Login" />;
    } else if (type === "public" && isLoggedIn) {
      return <Redirect to="/" />;
    }

    return (
      <Route {...rest} render={(matchProps) => <Component {...matchProps} />} />
    );
  }
}

GuardRoute.contextType = AuthContext;

export default GuardRoute;
