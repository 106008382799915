import { auth, db } from "./setup";

export function watchUserChanges(callback) {
  const unsub = auth.onAuthStateChanged((user) => {
    if (user && !user.isAnonymous) {
      let col = db.collection("users").doc(user.uid);

      col.onSnapshot((docUser) => {
        if (docUser.exists) {
          const userData = docUser.data();
          callback({
            code: user.uid,
            userData,
          });
        } else {
          console.log("No se ha creado el usuario");
          callback(null);
        }
      });
    } else {
      console.log("NOT logged in");
      callback(null);
    }
  });

  return unsub;
}

export function watchSelecteds(callback) {
  const unsub = db
    .collection("config")
    .doc("selecteds")
    .onSnapshot((doc) => {
      callback(doc.data());
    });
  return unsub;
}

export function watchTransmision(callback) {
  const unsub = db
    .collection("config")
    .doc("transmision")
    .onSnapshot((doc) => {
      callback(doc.data().bingo);
    });

  return unsub;
}
export function verifyId(values, callback) {
  const unsub = db
    .collection("users")
    .where("code", "==", values.code)
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.size > 0) {
        callback(true);
      } else {
        callback(false);
      }
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
    });

  return unsub;
}

export function invitedId(invitedId, callback) {
  const unsub = db
    .collection("accessList")
    .doc(`${invitedId}`)
    .get()
    .then(function (doc) {
      let result = {
        call: 0,
        dateAndTime: null,
        guestName: null,
        city: null,
        transmision: null,
      };
      if (doc.exists) {
        const data = doc.data();
        if (data.count < 2) {
          let result = {
            call: 1,
            dateAndTime: data.dateAndTime,
            guestName: data.names,
            city: data.city,
            transmision: data.transmision,
            numberOfCartons: data.numberOfCartons,
          };
          callback(result);
        } else {
          result.call = 2;
          callback(result);
        }
      } else {
        callback(result);
      }
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
    });

  return unsub;
}
