import Background from "../../assets/image/Sol.svg";
const styles = (theme) => ({
  sol: {
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${Background})`,
    backgroundPosition: "50% -83px",
  },
  card: {
    backgroundColor: "#fff",
    padding: "10px",
    borderRadius: "10px",
    marginBottom: "10px",
    position: "relative",
  },
  tabletBingo: {
    borderRadius: "11px !important",
    backgroundColor: "transparent",
    borderSpacing: "0 !important",
    borderCollapse: "inherit !important",
    border: "none !important",
    width: "100%",
    color: "#000",
  },
  selected: {
    background: "#0fb9b6 !important",
    color: "#ffffff !important",
  },
  bingo: {
    color: "#ffffff !important",
    textAlign: "center !important",
    fontSize: "38px !important",
    fontWeight: "bold !important",
    backgroundColor: "#e20613 !important",
    border: "#144377 1px solid",
    paddingTop: "5px !important",
    paddingBottom: "5px !important",
  },
  bingonummer: {
    padding: "0",
    width: "60px",
    height: "45px",
    paddingTop: "3px !important",
    paddingBottom: "2px !important",
    fontSize: "26px !important",
    textAlign: "center !important",
    border: "1px solid #afafaf !important",
  },
  tr: {
    display: "table-row",
    verticalAlign: "inherit",
    borderColor: "inherit",
  },
  td: {
    padding: "0",
  },
  th: {
    padding: "0",
  },
  logo: {
    marginBottom: "2px",
  },
  bingoimage: {
    marginBottom: "-10px",
    width: "90%",
  },
  logoBingo: {
    textAlign: "center",
  },
  messages: {
    borderRadius: "4px",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    position: "absolute",
    height: "100%",
    width: "100%",
  },
  bottomCloseManitoAbajo: {
    marginTop: "21%",
  },
  bottomCloseEresElGanador: {
    marginTop: "26%",
  },
  bottomCloseCaracol: {
    marginTop: "11%",
  },
  [theme.breakpoints.down("md")]: {
    bottomCloseManitoAbajo: {
      marginTop: "23%",
    },
    bottomCloseEresElGanador: {
      marginTop: "23%",
    },
    bottomCloseCaracol: {
      marginTop: "13%",
    },
  },
});

export default styles;
