import React from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { chromeTabsStylesHook } from "@mui-treasury/styles/tabs";
import "./styles.css";

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const ChromeTabs = (props) => {
  const tabsStyles = chromeTabsStylesHook.useTabs();
  const tabItemStyles = chromeTabsStylesHook.useTabItem();
  const { handleChange, tabIndex } = props;
  return (
    <Tabs
      classes={tabsStyles}
      value={tabIndex}
      onChange={handleChange}
      style={{
        marginTop: "5px",
        borderTopRightRadius: "10px",
        borderTopLeftRadius: "10px",
        // borderTop: "#000 1px solid",
        // borderRight: "#000 1px solid",
        // borderLeft: "#000 1px solid",
      }}
      variant="fullWidth"
      scrollButtons="auto"
    >
      {props.bingo.map((value, index) => {
        return (
          <Tab
            classes={tabItemStyles}
            label={`Card ${index + 1}`}
            {...a11yProps(index)}
            key={index}
          />
        );
      })}
    </Tabs>
  );
};

ChromeTabs.propTypes = {
  children: PropTypes.node,
  handleChange: PropTypes.any.isRequired,
  tabIndex: PropTypes.any.isRequired,
  bingo: PropTypes.any.isRequired,
};

export default ChromeTabs;
