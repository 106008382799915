import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";

import styles from "./styles";

class AlertDialog extends React.Component {
  render() {
    return (
      <div>
        <Dialog
          open={this.props.openDialog}
          onClose={this.props.handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'>
          <DialogTitle id='alert-dialog-title'>{this.props.title}</DialogTitle>

          <DialogContent>
            <DialogContentText
              id='alert-dialog-description'
              color='textPrimary'>
              {this.props.message}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={this.props.handleClose}
              color='primary'>
              No
            </Button>
            <Button
              onClick={this.props.handleYesDialog}
              color='primary'
              autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(AlertDialog);
