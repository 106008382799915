import React from "react";
import { Switch, Redirect } from "react-router-dom";
import GuardRoute from "./components/guardRoute";
import { Login as LoginView, Dashboard as DashboardView } from "./views";

const Routes = () => {
  return (
    <Switch>
      <GuardRoute type="public" path="/Login" component={LoginView} />
      <GuardRoute type="private" path="/Inico" component={DashboardView} />
      <Redirect from="/" to="/Inico" />
    </Switch>
  );
};

export default Routes;
