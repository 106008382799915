import Background from "../../assets/image/Sol.svg";
const styles = (theme) => ({
  sol: {
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${Background})`,
    backgroundPosition: "50% -53px",
    width: "380px",
    [theme.breakpoints.down(800)]: {
      width: "350px",
    },
    [theme.breakpoints.down(720)]: {
      width: "300px",
    },
    [theme.breakpoints.down(600)]: {
      width: "380px",
    },
  },
  swipeableViews: {
    backgroundColor: "#ffffff",
    borderRadius: "0 0 10px 10px",
    height: "420px !important",
  },
});

export default styles;
