// import movilBk from "../../assets/image/FONDO-largo.png";

const styles = (theme) => ({
  root: {
    height: "100vh",
  },
  // body: {
  //   [theme.breakpoints.down("sm")]: {
  //     backgroundImage: `url(${movilBk})`,
  //   },
  // },
  image: {
    width: "90%",
    padding: "10% 0 0 0",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  paper: {
    margin: theme.spacing(1, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: "10px",
    padding: "30px",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  textField: {
    margin: theme.spacing(1, 1),
    width: "97%",
  },
  formControl: {
    width: "97%",
    margin: theme.spacing(1, 1),
  },
  icon: {
    borderRadius: 3,
    width: 18,
    height: 18,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    fontSize: "25px",
  },
  flotante: {
    position: "fixed",
    bottom: 10,
    left: 10,
    cursor: "pointer",
    textAlign: "center",
  },
  flotante2: {
    position: "fixed",
    bottom: 0,
    left: 10,
  },
  flotante3: {
    position: "fixed",
    top: 0,
    left: 0,
  },
  textSupport: {
    fontSize: "15px",
  },
  pack: {
    borderRadius: "10px",
    color: "#fff",
    backgroundColor: "#075e54",
    padding: "5px",
  },
  [theme.breakpoints.down("lg")]: {
    icon: {
      fontSize: "15px",
    },
    textSupport: {
      fontSize: "12px",
    },
  },
  [theme.breakpoints.down("md")]: {
    icon: {
      fontSize: "12px",
    },
    textSupport: {
      fontSize: "10px",
    },
  },
  [theme.breakpoints.down("sm")]: {
    icon: {
      fontSize: "12px",
    },
    textSupport: {
      fontSize: "10px",
    },
  },
  logoss: {
    margin: "30px 0",
  },
  marcas: {
    backgroundColor: "#ffffff",
    borderRadius: "15px",
    margin: "20px 32px",
  },
});

export default styles;
