import React from "react";
import styles from "./styles";
import Bingo from "../../assets/image/bingo.svg";

// MATERIAL UI
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
//Recursos
import Ruleta from "../../assets/image/Ruleta.svg";
import ManitoAbajo from "../../assets/image/ManitoAbajo.svg";
import EresElGanador from "../../assets/image/ERES_GANADOR_DEL_BINGO.svg";
import x from "../../assets/image/x.svg";
import Caracol from "../../assets/image/Caracol.svg";
import Reiniciar from "../../assets/image/Reiniciar.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction='up'
      ref={ref}
      {...props}
    />
  );
});

class Card extends React.Component {
  constructor() {
    super();
    this.state = {
      cards: [],
      selected: [],
      startTime: new Date(),
      openDialog: false,
      message: "Are you sure you want to call BINGO?",
      openReset: false,
    };
    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleOpenReset = this.handleOpenReset.bind(this);
    this.handleCloseReset = this.handleCloseReset.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  handleOnClick = (event) => {
    let selected = this.state.selected; //en ms
    if (!selected.includes(parseInt(event.currentTarget.textContent))) {
      selected.push(parseInt(event.currentTarget.textContent));
      this.setState({
        selected: selected,
      });
    } else {
      var i = selected.indexOf(parseInt(event.currentTarget.textContent));
      selected.splice(i, 1);
      this.setState({
        selected: selected,
      });
    }

    let selectedStorage =
      localStorage.getItem("selected") !== null
        ? JSON.parse(localStorage.getItem("selected"))
        : [];

    selectedStorage[this.props.index] = selected;

    localStorage.setItem("selected", JSON.stringify(selectedStorage));
  };

  componentDidMount() {
    const { user, index } = this.props;
    const bingo = user.bingo;
    let letters = ["B", "I", "N", "G", "O"];
    let cards = [];
    for (var a = 0; a < 5; a++) {
      let fila = [];
      for (var b = 0; b < 5; b++) {
        fila.push(bingo[index][letters[b]][a]);
      }
      cards.push(fila);
    }
    const changeOne = cards[2];
    const changeTwo = cards[4];
    cards[2] = changeTwo;
    cards[4] = changeOne;

    let selectedStorage =
      localStorage.getItem("selected") !== null
        ? JSON.parse(localStorage.getItem("selected"))
        : [];

    let selected =
      selectedStorage.length === 0
        ? []
        : selectedStorage[this.props.index] === null
        ? []
        : selectedStorage[this.props.index] === undefined
        ? []
        : selectedStorage[this.props.index];
    this.setState({ cards, selected });
  }

  handleOpenReset() {
    this.setState({
      openReset: true,
    });
  }

  handleCloseReset() {
    this.setState({
      openReset: false,
    });
  }

  handleReset() {
    let selectedStorage =
      localStorage.getItem("selected") !== null
        ? JSON.parse(localStorage.getItem("selected"))
        : [];

    if (
      selectedStorage.length !== 0 &&
      selectedStorage[this.props.index] !== null &&
      selectedStorage[this.props.index] !== undefined
    ) {
      selectedStorage[this.props.index] = [];
      localStorage.setItem("selected", JSON.stringify(selectedStorage));
      this.setState({ selected: [], openReset: false });
    }
  }

  render() {
    const { cards, selected } = this.state;
    const { classes, user, transmision } = this.props;
    const winner = user.winners[this.props.index];

    return (
      <React.Fragment>
        <Box
          display='flex'
          justifyContent='center'>
          <Box p={1}>
            <div className={classes.card}>
              <div className={classes.logoBingo}>
                <img
                  src={Bingo}
                  alt='Bingo'
                  className={classes.bingoimage}
                />
              </div>
              <table className={classes.tabletBingo}>
                <tbody>
                  {cards.map((card, index1) => {
                    return (
                      <tr key={index1}>
                        {card.map((row, index2) => {
                          return (
                            <td
                              className={`${classes.bingonummer} ${
                                selected.includes(row) ? classes.selected : ""
                              } ${row === undefined ? classes.bingo : ""}`}
                              onClick={
                                row !== undefined
                                  ? this.handleOnClick
                                  : this.handleOpenReset
                              }
                              key={index2}>
                              {row !== undefined ? (
                                row
                              ) : (
                                <div>
                                  <img
                                    src={Reiniciar}
                                    style={{ width: "15px", height: "15px" }}
                                    alt='Reiniciar'
                                  />
                                  <br />
                                  <div
                                    style={{
                                      fontSize: "10px",
                                      color: "white",
                                    }}>
                                    Restart
                                  </div>
                                </div>
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <Box
                display='flex'
                justifyContent='center'
                mt={2}>
                {transmision === user.transmision ? (
                  <Button
                    variant='contained'
                    color='primary'
                    disableElevation
                    onClick={() =>
                      this.props.handleOpen(
                        user,
                        this.state.selected,
                        this.props.index
                      )
                    }
                    size='large'
                    disabled={
                      winner !== 1 ? true : user.toBlock ? true : false
                    }>
                    BINGO
                  </Button>
                ) : (
                  <Typography
                    variant='subtitle2'
                    color='textPrimary'
                    align='center'>
                    Your bingo game is scheduled for {user.dateAndTime}
                  </Typography>
                )}
              </Box>

              {user.toBlock ? (
                <Box
                  display='flex'
                  justifyContent='center'
                  className={classes.messages}
                  style={{ background: "rgba(245, 0, 0, 0.80)" }}>
                  <Box>
                    <div style={{ textAlign: "center", marginTop: "20%" }}>
                      <img
                        src={x}
                        width='30%'
                        alt='x'></img>
                    </div>
                    <Typography
                      variant='subtitle2'
                      color='textSecondary'
                      align='justify'
                      style={{ margin: "0 5%" }}>
                      Our system has permanently blocked you due to your
                      inappropriate behavior.
                    </Typography>
                  </Box>
                </Box>
              ) : winner === 2 ? (
                <Box
                  display='flex'
                  justifyContent='center'
                  className={classes.messages}
                  style={{ background: "rgba(0, 0, 0, 0.85)" }}>
                  <Box>
                    <div style={{ textAlign: "center", marginTop: "20%" }}>
                      <img
                        src={Ruleta}
                        width='30%'
                        alt='Ruleta'></img>
                    </div>
                    <Typography
                      variant='subtitle2'
                      color='textSecondary'
                      align='justify'
                      style={{ margin: "0 5%" }}>
                      Our game and entertainment team is verifying if your card
                      is correctly marked... <br></br>
                      We’ll announce during the broadcast if you are the lucky
                      winner.
                    </Typography>
                  </Box>
                </Box>
              ) : winner === 3 ? (
                <Box
                  display='flex'
                  justifyContent='center'
                  className={classes.messages}
                  style={{ background: "rgba(245, 0, 0, 0.80)" }}>
                  <Box>
                    <div style={{ textAlign: "center", marginTop: "20%" }}>
                      <img
                        src={ManitoAbajo}
                        width='30%'
                        alt='ManitoAbajo'></img>
                    </div>
                    <Typography
                      variant='subtitle2'
                      color='textSecondary'
                      align='justify'
                      style={{ margin: "0 5%" }}>
                      You haven’t won. Remember, you only have three chances to
                      click on BINGO. If you fail three times, you’ll be
                      temporarily blocked.
                    </Typography>
                    <Box
                      display='flex'
                      justifyContent='center'
                      className={classes.bottomCloseManitoAbajo}>
                      <Button
                        variant='contained'
                        color='primary'
                        disableElevation
                        onClick={() =>
                          this.props.updateUser(user, this.props.index)
                        }
                        size='large'>
                        CERRAR
                      </Button>
                    </Box>
                  </Box>
                </Box>
              ) : winner === 4 ? (
                <Box
                  display='flex'
                  justifyContent='center'
                  className={classes.messages}
                  style={{ background: "rgba(22, 251, 104, 0.90)" }}>
                  <Box>
                    <div style={{ textAlign: "center", marginTop: "35%" }}>
                      <img
                        src={EresElGanador}
                        width='100%'
                        alt='EresElGanador'></img>
                    </div>
                  </Box>
                </Box>
              ) : winner === 5 ? (
                <Box
                  display='flex'
                  justifyContent='center'
                  className={classes.messages}
                  style={{ background: "rgba(245, 0, 0, 0.80)" }}>
                  <Box>
                    <div style={{ textAlign: "center", marginTop: "20%" }}>
                      <img
                        src={Caracol}
                        width='30%'
                        alt='Caracol'
                      />
                    </div>
                    <Typography
                      variant='subtitle2'
                      color='textSecondary'
                      align='justify'
                      style={{ margin: "0 5%" }}>
                      Fuiste muy lento, tienes que ser más rapido.
                    </Typography>
                    <Box
                      display='flex'
                      justifyContent='center'
                      className={classes.bottomCloseCaracol}>
                      <Button
                        variant='contained'
                        color='primary'
                        disableElevation
                        onClick={() =>
                          this.props.updateUser(user, this.props.index)
                        }
                        size='large'>
                        CERRAR
                      </Button>
                    </Box>
                  </Box>
                </Box>
              ) : winner === 6 ? (
                <Box
                  display='flex'
                  justifyContent='center'
                  className={classes.messages}
                  style={{ background: "rgba(245, 0, 0, 0.80)" }}>
                  <Box>
                    <div style={{ textAlign: "center", marginTop: "20%" }}>
                      <img
                        src={x}
                        width='30%'
                        alt='x'></img>
                    </div>
                    <Typography
                      variant='subtitle2'
                      color='textSecondary'
                      align='justify'
                      style={{ margin: "0 5%" }}>
                      You’ve been blocked. Please wait for the next game to
                      continue playing..
                    </Typography>
                  </Box>
                </Box>
              ) : winner === 7 ? (
                <Box
                  display='flex'
                  justifyContent='center'
                  className={classes.messages}
                  style={{ background: "rgba(22, 251, 104, 0.90)" }}>
                  <Box>
                    <div style={{ textAlign: "center", marginTop: "35%" }}>
                      <img
                        src={EresElGanador}
                        width='100%'
                        alt='EresElGanador'></img>
                    </div>
                    <Box
                      display='flex'
                      justifyContent='center'
                      style={{ marginTop: "10px" }}>
                      <Button
                        variant='contained'
                        color='primary'
                        disableElevation
                        onClick={() =>
                          this.props.updateUser(user, this.props.index)
                        }
                        size='large'>
                        CERRAR
                      </Button>
                    </Box>
                  </Box>
                </Box>
              ) : null}
            </div>
            <Typography
              variant='subtitle2'
              color='textPrimary'
              align='center'>
              N°. Card: {user.code}-{this.props.index + 1}
            </Typography>
          </Box>
        </Box>
        <Dialog
          open={this.state.openReset}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleCloseReset}>
          <DialogTitle>{"Seguro quieres reiniciar este cartón?"}</DialogTitle>
          <DialogContent>
            <DialogContentText style={{ color: "black" }}>
              If you reset the card, you will not be able to recover the numbers
              you have marked.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseReset}
              color='primary'>
              No
            </Button>
            <Button
              onClick={this.handleReset}
              color='primary'>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Card);
