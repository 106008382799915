import React from "react";
import { BrowserRouter } from "react-router-dom";
import Root from "./components/root";
import { AuthContextProvider } from "./context/auth";
import { UserContextProvider } from "./context/users";
import Routes from "./Routes";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./theme";

export default class AlertDialog extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AuthContextProvider>
            <UserContextProvider>
              <Root>
                <Routes />
              </Root>
            </UserContextProvider>
          </AuthContextProvider>
        </ThemeProvider>
      </BrowserRouter>
    );
  }
}
