import firebase from "firebase";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage;
export const firebaseAuth = firebase.auth.Auth;
export const updateFirebase = firebase.firestore.FieldValue;
export const serverValue = firebase.database.ServerValue;

export const startRecaptcha = (callback) => {
  window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("recaptcha", {
    callback: () => {
      callback(true);
    },
    "expired-callback": () => {
      callback(false);
    },
    hl: "en",
  });
  window.recaptchaVerifier.render();
};
